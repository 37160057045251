import React, { memo } from "react";
import {
  Instagram,
  Telegram,
  WhatsApp,
  Language,
  Facebook,
} from "@mui/icons-material";
import { Conversation } from "../../store/slices/conversationsSlice";
import { NavLink } from "react-router-dom";
import ProfilePhoto from "../ProfilePhoto";
import moment from "moment";
import { conversationMenuMobileToggle } from "../../utils";

type Props = {
  conversation: Conversation;
};
const ConversationItem: React.FC<Props> = ({ conversation }) => {
  const isThereUnreadMessage = !!conversation.messages.find(
    (item) => item.role === "user" && item.status !== "read",
  );

  return (
    <NavLink
      className={({ isActive }) =>
        `flex items-center border-b-2 p-2 lg:hover:bg-gray-200 cursor-pointer relative ${isActive ? "lg:bg-gray-200" : isThereUnreadMessage ? "bg-blue-50" : ""}`
      }
      to={`/dashboard/messages/${conversation._id}`}
      onClick={conversationMenuMobileToggle}
    >
      {isThereUnreadMessage ? (
        <div className="absolute top-1 right-1 inline-flex rounded-full h-2 w-2 bg-blue-400" />
      ) : null}
      <div className="relative">
        <ProfilePhoto
          name={conversation.name}
          photoUrl={conversation.photoUrl}
          size={48}
        />
        <div className="absolute bg-white bottom-[-4px] right-[0px] rounded-full w-[20px] h-[20px] flex items-center justify-center shadow">
          {conversation.source === "web" ? (
            <Language sx={{ fontSize: 18, color: "#00a2e8" }} />
          ) : conversation.source === "whatsapp" ? (
            <WhatsApp sx={{ fontSize: 18, color: "#25D366" }} />
          ) : conversation.source === "messenger" ? (
            <Facebook sx={{ fontSize: 18, color: "#1877F2" }} />
          ) : conversation.source === "instagram" ? (
            <Instagram sx={{ fontSize: 18, color: "#DD2A7B" }} />
          ) : conversation.source === "telegram" ? (
            <Telegram sx={{ fontSize: 18, color: "#24A1DE" }} />
          ) : null}
        </div>
      </div>

      <div className="ms-2 w-[calc(100%-48px-0.5rem)] flex flex-col">
        <div className="flex items-center justify-between mb-1">
          <h3 className="font-bold text-sm">
            {conversation.name.slice(0, 13)}
          </h3>
          <span className="text-gray-500 text-xs pe-1">
            {moment(conversation.lastMessage.createdAt).fromNow()}
          </span>
        </div>
        <p
          className={`text-xs text-ellipsis overflow-hidden text-gray-500 whitespace-nowrap max-w-[70vw] lg:max-w-[240px] ${isThereUnreadMessage ? "font-bold" : ""}`}
        >
          {conversation.lastMessage.content}
        </p>
      </div>
    </NavLink>
  );
};

export default memo(ConversationItem);
