export const ChartPastOptions = [
  {
    label: "Son 7 gün",
    value: "7",
  },
  {
    label: "Son 14 gün",
    value: "14",
  },
  {
    label: "Son 30 gün",
    value: "30",
  },
];
