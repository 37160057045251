import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import {
  assignToAssistant,
  Product,
  sendMessage,
  updateMessageStatus,
} from "../../store/slices/conversationsSlice";
import { useParams } from "react-router-dom";
import ChatMessageSend from "./ChatMessageSend";
import { CHAT_MENU_TYPES } from "./types";
import EmojiMenu from "./EmojiMenu";
import ProductSelectMenu from "./ProductSelectMenu";
import { EmojiClickData } from "emoji-picker-react";
import DiscountsMenu from "./DiscountsMenu";
import FastReplies from "./FastReplies";
import {
  REQUEST_CUSTOMER_ASSIGN_TO_AGENT,
  REQUEST_SEND_MESSAGE,
} from "../../api/requests";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../store/store";
import ProfilePhoto from "../ProfilePhoto";
import { Team } from "../../store/slices/teamSlice";

type Props = {
  isAIDisabled: boolean;
  assignedAgentId?: string;
  customerId: string;
};
const ChatInput: React.FC<Props> = ({
  isAIDisabled,
  assignedAgentId,
  customerId,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    company: { userId },
  } = useSelector((state: RootState) => state.auth);
  const { team } = useSelector((state: RootState) => state.team);
  const [message, setMessage] = useState("");
  const [activeMenu, setActiveMenu] = useState<CHAT_MENU_TYPES | null>(null);
  const [assignLoading, setAssignLoading] = useState("");
  const [assignedAgent, setAssignedAgent] = useState<Team>();
  if (!params.chatId && typeof params.chatId !== "string") {
    return null;
  }
  const chatId = params.chatId as string;

  useMemo(() => {
    const foundAgent = team.find((member) => member._id === assignedAgentId);
    if (foundAgent) {
      setAssignedAgent(foundAgent);
    }
  }, [assignedAgentId]);

  const handleSendMessage = (message: string, products?: Product[]) => {
    if (message.trim() || products?.length) {
      const tmpMessageId = uuidv4();
      dispatch(
        sendMessage({
          _id: tmpMessageId,
          id: tmpMessageId,
          conversationId: chatId,
          content: message,
          products,
        }),
      );
      REQUEST_SEND_MESSAGE({
        customerId,
        messageText: message.trim(),
        products,
      })
        .then(({ data }) => {
          dispatch(
            updateMessageStatus({
              messageId: tmpMessageId,
              newMessageId: data.data.message._id,
              conversationId: chatId,
              status: "sent",
            }),
          );
        })
        .catch(() => {
          dispatch(
            updateMessageStatus({
              messageId: tmpMessageId,
              conversationId: chatId,
              status: "error",
            }),
          );
        });
    }
  };
  const handleCloseMenu = () => {
    setActiveMenu(null);
  };

  const onEmojiClick = (emojiObject: EmojiClickData) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleAssignToMe = () => {
    setAssignLoading(customerId);
    REQUEST_CUSTOMER_ASSIGN_TO_AGENT({ customerId, agentId: userId })
      .then(() => {
        dispatch(assignToAssistant({ customerId: chatId, agentId: userId }));
      })
      .catch(() => {
        toast.error("Error happen when assign conversation");
      })
      .finally(() => setAssignLoading(""));
  };

  return (
    <div
      className={`${isAIDisabled ? "bg-white" : "bg-gray-50"} relative py-2 px-5 flex items-center justify-between rounded-br-lg border-t-2`}
    >
      {activeMenu === "emoji" ? (
        <EmojiMenu
          handleCloseMenu={handleCloseMenu}
          onEmojiClick={onEmojiClick}
        />
      ) : activeMenu === "products" ? (
        <ProductSelectMenu
          handleSendMessage={handleSendMessage}
          handleCloseMenu={handleCloseMenu}
        />
      ) : activeMenu === "discounts" ? (
        <DiscountsMenu handleCloseMenu={handleCloseMenu} />
      ) : activeMenu === "fastReplies" ? (
        <FastReplies
          handleCloseMenu={handleCloseMenu}
          handleSendMessage={handleSendMessage}
        />
      ) : null}
      {isAIDisabled && assignedAgentId === userId ? (
        <div className="w-full">
          <ChatMessageSend
            handleSendMessage={handleSendMessage}
            message={message}
            setMessage={setMessage}
            openMenu={(menu: CHAT_MENU_TYPES) => setActiveMenu(menu)}
          />
        </div>
      ) : isAIDisabled && assignedAgentId !== userId ? (
        <div className="w-full flex items-center">
          {assignedAgent?.name ? (
            <>
              <ProfilePhoto
                name={assignedAgent.name}
                photoUrl={assignedAgent.photoUrl}
                size={36}
              />
              <div className="flex flex-col">
                <span className="text-gray-700">{assignedAgent.name}</span>
                <span className="text-gray-500 text-xs">
                  {t("this_conversation_assigned")}
                </span>
              </div>
            </>
          ) : (
            <span>{t("this_conversation_assigned")}</span>
          )}
        </div>
      ) : (
        <div className="w-full flex justify-between items-center">
          <span className="text-sm font-bold text-biens font-biens">
            biens.ai {t("is_active")}
          </span>
          <Button
            title={t("assign_to_me")}
            type={"button"}
            onClick={handleAssignToMe}
            isLoading={assignLoading === customerId}
          />
        </div>
      )}
    </div>
  );
};

export default ChatInput;
