import { Clear } from "@mui/icons-material";
import Dialog from "./index";
import React, { FormEventHandler, useRef, useState } from "react";
import Button from "../Button";
import {
  FastReply,
  Rule,
  setAIRules,
  setFastReplies,
  updateAIRule,
  updateFastReply,
} from "../../store/slices/authSlice";
import {
  REQUEST_ADD_AI_RULE,
  REQUEST_ADD_FAST_REPLY,
  REQUEST_PUT_AI_RULE,
  REQUEST_PUT_FAST_REPLY,
} from "../../api/requests";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

type Props = {
  closeDialog: () => void;
  fastReply?: FastReply;
};
const FastReplyDialog: React.FC<Props> = ({ closeDialog, fastReply }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(fastReply?.title || "");
  const [message, setMessage] = useState(fastReply?.title || "");
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let request;
    if (fastReply?._id) {
      request = REQUEST_PUT_FAST_REPLY({
        fastReplyId: fastReply._id,
        title,
        message,
      });
    } else {
      request = REQUEST_ADD_FAST_REPLY({ title, message });
    }
    request
      .then((res) => {
        if (fastReply?._id) {
          dispatch(
            updateFastReply({
              fastReplyId: fastReply._id,
              title,
              message,
            }),
          );
        } else if (res.data?.data?.data) {
          dispatch(setFastReplies(res.data.data.data));
        }
        handleCloseWithAnimation();
      })
      .catch(() => {
        toast.error("Error happen when adding/updating");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[50vw] animate-scale-anim"
      >
        <div className="flex items-center justify-between border-b-2 pb-2 mb-2">
          <h3 className="font-bold">{`${fastReply ? t("edit_fast_reply") : t("create_fast_reply")}`}</h3>
          <button
            className="hover:bg-gray-200 rounded-lg"
            onClick={handleCloseWithAnimation}
          >
            <Clear className="text-gray-700" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <h3 className="font-bold text-sm text-gray-700">Başlık</h3>
          <input
            type="text"
            className="w-full px-2 rounded-lg border-2 mt-1 text-sm py-2 focus:outline-0"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={t("add_fast_reply_title_placeholder")}
            autoFocus
          />
          <h3 className="font-bold text-sm text-gray-700 mt-2">Mesaj</h3>
          <textarea
            rows={4}
            className="w-full text-sm text-gray-900 bg-white focus:outline-0 min-h-14 max-h-60 border-2 rounded-lg p-2 mt-1 mb-2"
            placeholder={t("add_fast_reply_placeholder")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <Button
            type="submit"
            title={fastReply ? t("update") : t("create")}
            size="sm"
            isLoading={isSubmitting}
          />
        </form>
      </div>
    </Dialog>
  );
};

export default FastReplyDialog;
