const trTranslations = {
  login_header: "Giriş Yap",
  dont_have_an_account: "Henüz hesabın yok mu?",
  register: "Kayıt ol",
  email_input: "Email",
  email_input_placeholder: "email@gmail.com",
  password_input: "Şifre",
  password_input_placeholder: "••••••••",
  did_you_forgot_password: "Şifreni mi unuttun?",
  reset_password: "Sıfırla",
  reset_password_header: "Şifreni Sıfırla",
  back_page: "Geri dön",
  login_page: "giriş sayfası",
  we_will_send_reset_link: "Email adresine sıfırlama linki göndereceğiz.",
  send_button: "Gönder",
  thanks_for_interest: "İlginiz için teşekkür ederiz.",
  thanks_for_interest_desc:
    "Müşterilerimizin ihtiyaçlarını en iyi şekilde karşılamak ve en uygun çözümleri sunabilmek adına, sizinle bir ön görüşme gerçekleştirmeyi arzu ediyoruz.\n\nFirmamızın hizmetlerine erişim sağlamadan önce, sizinle bir demo sunumu gerçekleştirmek ve ihtiyaçlarınızı daha iyi anlamak amacıyla bir görüşme yapmayı önemsiyoruz. Bu görüşme, ihtiyaçlarınızı belirlememize ve size en uygun çözümü sunmamıza yardımcı olacaktır.\n\nDemo talebinizi oluşturmak için lütfen aşağıdaki iletişim bilgilerimizden birini kullanarak bizimle iletişime geçin\n\n",
  request_demo: "Demo Talebi Oluştur",
  analytics: "Analitik",
  messages: "Mesajlar",
  team: "Takım",
  customers: "Müşteriler",
  integrations: "Kanallar",
  chat_config: "Görünüm",
  settings: "Ayarlar",
  help: "Yardım",
  write_a_message: "Bir mesaj yazın",
  low_balance_warning: "Uyarı!",
  low_balance_warning_desc:
    "Mesaj bakiyeniz azalmaktadır. Müşterilerinizle kesintisiz etkileşimler için mesaj paketi satın alabilirsiniz.",
  message_package_button: "Mesaj paketleri",
  all_messages: "Bütün Mesajlar",
  assigned_to_me: "Gelen Kutum",
  is_active: "aktif",
  assign_to_me: "Konuşmayı devral",
  this_conversation_assigned: "Bu konuşma başka bir temsilciye atandı",
  assign_to_agent: "Temsilciye yönlendir",
  select_member: "Temsilci seçiniz",
  customer_profile: "Müşteri Profili",
  first_view: "İlk Ziyaret",
  last_view: "Son Ziyaret",
  total_visit: "Toplam Ziyaret",
  language: "Dil",
  country: "Ülke",
  state: "Şehir",
  timezone: "Zaman Dilimi",
  platform: "Platform",
  browser: "Tarayıcı",
  email: "Email",
  phone: "Telefon",
  my_team: "Takım",
  add_member: "Temsilci Ekle",
  name: "İsim",
  permissions: "Yetki",
  actions: "İşlem",
  permission: "Yetki",
  admin: "Admin",
  admin_desc:
    "Analitik, mesajlar, takım yönetimi, satın alımlar ve tüm ayarlar",
  executive: "Takım Yöneticisi",
  executive_desc: "Analitik, mesajlar, takım yönetimi",
  agent: "Temsilci",
  agent_desc: "Mesajlar",
  update_member: "Temsilci Güncelle",
  member_delete_confirm:
    "{{name}} kullanıcısını silmek istediğinize emin misiniz?",
  appearance_layout: "Ana Renkler",
  appearance_input: "Yazı Alanı",
  appearance_messages: "Mesajlar",
  appearance_position: "Konumlandırma",
  appearance_hbg: "Başlık arkaplanı",
  appearance_cbg: "Sohbet arkaplanı",
  appearance_astc: "Firma ismi",
  appearance_ostc: "Durum bilgisi",
  appearance_ibg: "Yazı alanı arkaplanı",
  appearance_itc: "Yazı rengi",
  appearance_it: "Ön izleme yazısı",
  appearance_it_tooltip: "Giriş alanı boş iken gösterilecek yazı",
  appearance_asic: "Gönder ikonu",
  appearance_rmbg: "Gelen mesaj balonu",
  appearance_rmtc: "Gelen mesaj yazısı",
  appearance_rmdc: "Gelen mesaj saati",
  appearance_smbg: "Giden mesaj balonu",
  appearance_smtc: "Giden mesaj yazısı",
  appearance_smdc: "Giden mesaj saati",
  appearance_dp: "Konum",
  appearance_dp_l: "Sol",
  appearance_dp_r: "Sağ",
  appearance_vm: "Yükseklik",
  appearance_hm: "Yandan uzaklık",
  reset: "Sıfırla",
  save: "Kaydet",
  are_you_sure_reset: "Sıfırlamak istediğinize emin misiniz?",
  not_be_revert: "Bu işlem geri alınamayacak",
  hard_reset: "biens varsayılanına sıfırla",
  reset_my_changes: "Son değişikliklerimi sıfırla",
  chat_config_updated: "Sohbet görünüm ayarları güncellendi.",
  chat_config_update_error:
    "Sohbet görünüm ayarları güncellenirken hata oluştu.",
  user_image: "Kullanıcı Fotoğrafı",
  change_picture: "Fotoğrafı Değiştir",
  your_user_name:
    "İsminiz, gönderdiğiniz mesajlarda ziyaretçilerinize gösterilir.",
  update: "Güncelle",
  security: "Güvenlik",
  change_password: "Şifreyi Değiştir",
  current_password: "Güncel Şifre",
  new_password: "Yeni Şifre",
  confirm_new_password: "Yeni Şifre (Tekrar)",
  settings_user: "Kullanıcı Ayarları",
  settings_company: "Firma Ayarları",
  settings_ai: "AI Ayarları",
  settings_subscription: "Satın Alımlar & Abonelikler",
  settings_usage: "Kullanım",
  chat_image: "Firmanızın Logosu",
  company_name: "Firmanızın Adı",
  company_name_desc: "Bu isim, ziyaretçilerinize sohbet ekranında gösterilir.",
  welcome_message: "Karşılama mesajı",
  welcome_message_desc: "Ziyaretçilerinize gönderilecek karşılama mesajı.",
  auto_assign: "biens.ai otomatik olarak atansın",
  auto_assign_desc:
    "Bu seçenek açık olduğunda, biens.ai otomatik olarak gelen mesajlara cevap verecektir.",
  ai_knowledge: "AI Talimatları",
  ai_knowledge_desc:
    "İşletmenize özel talimatları ekleyebilirsiniz. Biens.ai bu talimatlar doğrultusunda müşterilerinize hizmet verecektir.",
  add_rule: "Talimat Ekle",
  add_rule_placeholder:
    "Bir talimat yazınız... \nÖr: \n-Daha çok emoji kullan\n-Müşterilerin Ramazan Bayramını kutla",
  update_rule: "Talimat Güncelle",
  add_ai_rule: "AI Talimatı Ekle",
  edit_ai_rule: "AI Talimatı Düzenle",
  upload_new_company_photo: "Firma logosu yükle",
  upload_new_user_photo: "Profil fotoğrafı yükle",
  upload_photo: "Fotoğraf Yükle",
  drop_photo_here: "Lütfen fotoğrafı buraya sürükleyiniz",
  or: "veya",
  choose_from_files: "Dosyalardan seçin",
  image_rules:
    "Daha iyi bir deneyim için 1:1 formatında fotoğraf yüklemeniz önerilir.",
  supported_formats: "Desteklenen dosya formatları JPG, JPEG, PNG",
  web: "Web",
  web_desc:
    "Websitenize biens.ai chat'ı kurun. Ziyaretçilerinizin ihtiyaçlarını biens.ai cevaplandırsın!",
  whatsapp: "Whatsapp",
  whatsapp_desc:
    "Whatsapp hattınıza gelen mesajları biens.ai ile anında cevaplandırın!",
  instagram: "Instagram",
  instagram_desc:
    "Instagram hesabınıza gelen mesajları biens.ai ile anında cevaplandırın!",
  messenger: "Messenger",
  messenger_desc:
    "Facebook sayfanıza gelen mesajları biens.ai ile anında cevaplandırın!",
  telegram: "Telegram",
  telegram_desc:
    "Telegram hesabınıza gelen mesajları biens.ai ile anında cevaplandırın!",
  cancel: "İptal",
  confirm: "Onayla",
  confirm_delete_ai_rule: "AI Talimatını silmek istediğinize emin misiniz?",
  type_a_product_name: "Bir ürün adı yazınız",
  send_n_products: "({{count}}) ürünü gönder",
  no_products:
    "Ürün bulunamadı.\nLütfen arama içeriğini değiştirerek deneyiniz",
  go_to_product: "Ürüne git",
  fast_replies: "Hızlı yanıtlar",
  fast_replies_desc:
    "Burada tanımlayacağınız hızlı yanıtları mesajlar ekranında kullanabilirsiniz. Tanımlanan hızlı yanıtlar kullanıcılara özeldir. Profiliniz için tanımladığınız hızlı mesajlar, takımınızdaki diğer kişiler tarafından görüntülenemez veya kullanılamaz.",
  add_fast_reply: "Hızlı yanıt ekle",
  create_fast_reply: "Hızlı yanıt oluştur",
  edit_fast_reply: "Hızlı yanıt düzenle",
  create: "Oluştur",
  add_fast_reply_title_placeholder: "Hızlı yanıt başlığı yazınız",
  add_fast_reply_placeholder: "Bir hızlı yanıt yazınız.",
  confirm_delete_fast_reply: "Hızlı yanıtı silmek istediğinize emin misiniz?",
  fast_reply_delete_success: "Hızlı yanıt başarıyla silindi",
  you_have_not_fast_reply: "Henüz tanımlanmış bir hızlı yanıtınız yok",
  you_have_not_fast_reply_chat_screen:
    "Henüz tanımlanmış hızlı yanıtınız yok. Ayarlar > Hızlı yanıtlar menüsünden hızlı yanıt tanımlayabilirsiniz.",
  search_fast_reply_placeholder:
    "Hızlı yanıt başlığını veya içeriğini yazarak arayın",
  fast_send_description:
    "Mesaj göndermek için <strong>{{helpText}}</strong> tuşlarını kullanabilirsiniz.",
  fast_reply_empty_search_result:
    "Aradığınız kriterlerde hızlı yanıt bulunamadı.",
  no_data: "Veri bulunamadı",
};

export default trTranslations;
