import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import React, { useState } from "react";
import { deleteFastReply, FastReply } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { Delete, Edit, FlashOnOutlined } from "@mui/icons-material";
import FastReplyDialog from "../../components/Dialog/FastReplyDialog";
import { RootState } from "../../store/store";
import AlertDialog from "../../components/Dialog/Alert";
import { REQUEST_DELETE_FAST_REPLY } from "../../api/requests";
import { toast } from "react-toastify";

const FastReplies = () => {
  const { t } = useTranslation();
  const { fastReplies } = useSelector((state: RootState) => state.auth.company);
  const dispatch = useDispatch();
  const [fastReplyDialog, setFastReplyDialog] = useState<{
    status: boolean;
    fastReply?: FastReply;
  }>({
    status: false,
  });
  const [deleteDialog, setDeleteDialog] = useState<{
    status: boolean;
    fastReplyId: string;
  }>({ status: false, fastReplyId: "" });
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteFastReply = () => {
    setDeleteLoading(true);
    REQUEST_DELETE_FAST_REPLY(deleteDialog.fastReplyId)
      .then(() => {
        toast.success(t("fast_reply_delete_success"));
        dispatch(deleteFastReply(deleteDialog.fastReplyId));
        setDeleteDialog({
          status: false,
          fastReplyId: "",
        });
      })
      .catch((err) => {
        console.log("error", err);
        toast.error("Error happen when changing deleting ai rule");
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  return (
    <div className="container mx-auto">
      {fastReplyDialog.status ? (
        <FastReplyDialog
          closeDialog={() => setFastReplyDialog({ status: false })}
          fastReply={fastReplyDialog.fastReply}
        />
      ) : null}
      {deleteDialog.status ? (
        <AlertDialog
          closeDialog={() =>
            setDeleteDialog({ status: false, fastReplyId: "" })
          }
          alertText={t("confirm_delete_fast_reply")}
          alertType={"confirm"}
          confirmText={t("confirm")}
          onConfirm={handleDeleteFastReply}
          isConfirmLoading={deleteLoading}
        />
      ) : null}
      <div className="bg-white p-2 border-2 rounded-lg mb-2">
        <h3 className="font-bold text-sm text-gray-700">{t("fast_replies")}</h3>
        <span className="text-gray-500 text-xs">{t("fast_replies_desc")}</span>
        <div className="my-5">
          {!fastReplies.length ? (
            <div>
              <span className="text-gray-700 text-sm">
                {t("you_have_not_fast_reply")}
              </span>
            </div>
          ) : (
            fastReplies.map((fastReply) => (
              <div
                key={fastReply._id}
                className="relative border-2 rounded p-2 flex items-center cursor-pointer mb-2"
              >
                <FlashOnOutlined className="text-gray-700 me-2" />
                <div className="flex-1">
                  <h6 className="text-xs text-gray-700 font-bold pe-4">
                    {fastReply.title}
                  </h6>
                  <div className="text-xs text-gray-700">
                    {fastReply.message}
                  </div>
                </div>
                <div className="flex">
                  <Edit
                    className="text-gray-500 cursor-pointer transition duration-200 hover:transition hover:duration-200 hover:text-gray-700"
                    fontSize="small"
                    onClick={() =>
                      setFastReplyDialog({ status: true, fastReply })
                    }
                  />
                  <Delete
                    className="text-red-500 cursor-pointer ms-2 transition duration-200 hover:transition hover:duration-200 hover:text-red-600"
                    fontSize="small"
                    onClick={() => {
                      setDeleteDialog({
                        status: true,
                        fastReplyId: fastReply._id,
                      });
                    }}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <Button
          title={t("add_fast_reply")}
          type="button"
          className="mt-3"
          size="sm"
          onClick={() => setFastReplyDialog({ status: true })}
          isLoading={false}
        />
      </div>
    </div>
  );
};

export default FastReplies;
