import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type Option = {
  value: string;
  label: string;
};

type Props = {
  label: string;
  options: Option[];
  selectedValue: string;
  handleChangeValue: (newVal: string) => void;
};
const DropdownSelect: React.FC<Props> = ({
  label,
  options,
  selectedValue,
  handleChangeValue,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    handleChangeValue(event.target.value);
  };
  return (
    <FormControl sx={{ minWidth: 140 }} size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedValue}
        label="Age"
        onChange={handleChange}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownSelect;
