import React, { memo, useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import MainCard from "./MainCard";
import { Search, FlashOnOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import { FastReply } from "../../store/slices/authSlice";
import { Product } from "../../store/slices/conversationsSlice";

type Props = {
  handleCloseMenu: () => void;
  handleSendMessage: (message: string, products?: Product[]) => void;
};

const FastReplies: React.FC<Props> = ({
  handleCloseMenu,
  handleSendMessage,
}) => {
  const { t } = useTranslation();
  const { fastReplies } = useSelector((state: RootState) => state.auth.company);
  const [filteredReplies, setFilteredReplies] = useState<FastReply[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (!search.trim()) {
      setFilteredReplies(fastReplies);
      return;
    }

    const newFilteredReplies = fastReplies.filter(
      (item) =>
        item.message.toLowerCase().includes(search.toLowerCase()) ||
        item.title.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredReplies(newFilteredReplies);
  }, [search]);

  return (
    <div className="absolute top-[-510px] left-0">
      <ClickAwayListener onClickAway={handleCloseMenu}>
        <div className="px-5">
          <MainCard elevation={8} content={false}>
            <div className="flex flex-col justify-between max-w-[400px] min-w-[400px] h-[500px] bg-white">
              <div className="p-2 border-b-2 h-[55px]">
                <div className="border-2 rounded-lg flex items-center py-1 px-2">
                  <Search className="text-gray-700" />
                  <input
                    type="text"
                    className="block w-full text-sm text-gray-700 outline-0 border-0 focus:outline-0 focus:border-0 mx-2 h-[24px]"
                    placeholder={t("search_fast_reply_placeholder")}
                    required
                    autoFocus
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="p-2 h-full overflow-y-auto">
                {!fastReplies.length ? (
                  <div className="h-full w-full flex items-center justify-center">
                    <span className="text-gray-700 text-sm text-center">
                      {t("you_have_not_fast_reply_chat_screen")}
                    </span>
                  </div>
                ) : !filteredReplies.length ? (
                  <div className="h-full w-full flex items-center justify-center">
                    <span className="text-gray-700 text-sm text-center">
                      {t("fast_reply_empty_search_result")}
                    </span>
                  </div>
                ) : (
                  filteredReplies.map((fastReply) => (
                    <div
                      key={fastReply._id}
                      onClick={() => {
                        handleSendMessage(fastReply.message);
                        handleCloseMenu();
                      }}
                      className="relative border-2 rounded p-2 flex items-center transition hover:transition hover:bg-gray-50 cursor-pointer mb-2"
                    >
                      <FlashOnOutlined className="text-gray-700 me-2" />
                      <div>
                        <h6 className="text-xs text-gray-700 font-bold pe-4">
                          {fastReply.title}
                        </h6>
                        <div className="text-xs text-gray-700">
                          {fastReply.message}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </MainCard>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default memo(FastReplies);
