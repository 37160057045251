import axios from "axios";
import {
  ADD_TEAM_MEMBER_ENDPOINT,
  AI_RULE_ENDPOINT,
  CHANGE_PASSWORD_ENDPOINT,
  CONVERSATION_ENDPOINT,
  CUSTOMER_ASSIGN_TO_AGENT_ENDPOINT,
  CUSTOMERS_ENDPOINT,
  DASHBOARD_COMPANY_ENDPOINT,
  FAST_REPLY_ENDPOINT,
  GET_ANALYTICS_ENDPOINT,
  GET_MESSAGES_ENDPOINT,
  GET_PREVIOUS_MESSAGES_ENDPOINT,
  GET_TEAM_MEMBERS_ENDPOINT,
  LOGIN_ENDPOINT,
  PUT_AUTO_ASSIGN_BIENS_ENDPOINT,
  PUT_CHAT_CONFIG_ENDPOINT,
  PUT_COMPANY_NAME_ENDPOINT,
  PUT_MEMBER_NAME_ENDPOINT,
  PUT_MESSAGE_STATUS_ENDPOINT,
  PUT_TEAM_MEMBER_ENDPOINT,
  PUT_WELCOME_MESSAGE_ENDPOINT,
  REMOVE_TEAM_MEMBER_ENDPOINT,
  REMOVE_TELEGRAM_BOT_ENDPOINT,
  SEARCH_PRODUCTS_ENDPOINT,
  SEND_MESSAGE_ENDPOINT,
  SET_TELEGRAM_BOT_ENDPOINT,
  UPLOAD_COMPANY_PHOTO_ENDPOINT,
  UPLOAD_USER_PHOTO_ENDPOINT,
} from "./endpoints";
import { Company } from "../store/slices/authSlice";
import { Product } from "../store/slices/conversationsSlice";
export const REQUEST_LOGIN = (email: string, password: string) =>
  axios.post(LOGIN_ENDPOINT, { email, password });

export const REQUEST_DASHBOARD_COMPANY = () =>
  axios.get(DASHBOARD_COMPANY_ENDPOINT);

export const REQUEST_CUSTOMERS = () => axios.get(CUSTOMERS_ENDPOINT);

export const REQUEST_GET_ANALYTICS = ({ days }: { days: number }) =>
  axios.get(GET_ANALYTICS_ENDPOINT, {
    params: {
      days,
    },
  });

export const REQUEST_GET_MESSAGES = ({
  page,
  assignedToMe,
  isAutoAI,
}: {
  page: number;
  assignedToMe?: boolean;
  isAutoAI?: boolean;
}) =>
  axios.get(GET_MESSAGES_ENDPOINT, {
    params: {
      page: page,
      assignedToMe,
      isAutoAI,
    },
  });

export const REQUEST_GET_PREVIOUS_MESSAGES = ({
  customerId,
  createdAt,
}: {
  customerId: string;
  createdAt: number;
}) => axios.post(GET_PREVIOUS_MESSAGES_ENDPOINT, { customerId, createdAt });
export const REQUEST_GET_TEAM_MEMBERS = () =>
  axios.get(GET_TEAM_MEMBERS_ENDPOINT);

export const REQUEST_PUT_COMPANY_NAME = (companyName: string) =>
  axios.put(PUT_COMPANY_NAME_ENDPOINT, { companyName });
export const REQUEST_PUT_MEMBER_NAME = (name: string) =>
  axios.put(PUT_MEMBER_NAME_ENDPOINT, { name });
export const REQUEST_PUT_WELCOME_MESSAGE = (welcomeMessage: string) =>
  axios.put(PUT_WELCOME_MESSAGE_ENDPOINT, { welcomeMessage });
export const REQUEST_PUT_CHAT_CONFIG = (chatConfig: Company["chatConfig"]) =>
  axios.put(PUT_CHAT_CONFIG_ENDPOINT, { chatConfig });

export const REQUEST_CHANGE_PASSWORD = (
  password: string,
  newPassword: string,
  confirmPassword: string,
) =>
  axios.post(CHANGE_PASSWORD_ENDPOINT, {
    password,
    newPassword,
    confirmPassword,
  });

export const REQUEST_SET_TELEGRAM_BOT = (telegramBotId: string) =>
  axios.post(SET_TELEGRAM_BOT_ENDPOINT, { telegramBotId });

export const REQUEST_REMOVE_TELEGRAM_BOT = () =>
  axios.get(REMOVE_TELEGRAM_BOT_ENDPOINT);

export const REQUEST_UPDATE_COMPANY_PHOTO = (formData: FormData) =>
  axios.post(UPLOAD_COMPANY_PHOTO_ENDPOINT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const REQUEST_UPDATE_USER_PHOTO = (formData: FormData) =>
  axios.post(UPLOAD_USER_PHOTO_ENDPOINT, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const REQUEST_REMOVE_TEAM_MEMBER = (teamMemberId: string) =>
  axios.post(REMOVE_TEAM_MEMBER_ENDPOINT, { teamMemberId });

export const REQUEST_ADD_TEAM_MEMBER = (
  name: string,
  email: string,
  permission: string,
) => axios.post(ADD_TEAM_MEMBER_ENDPOINT, { name, email, permission });

export const REQUEST_PUT_TEAM_MEMBER = (name: string, permission: string) =>
  axios.put(PUT_TEAM_MEMBER_ENDPOINT, { name, permission });

export const REQUEST_PUT_AUTO_ASSIGN_BIENS = (status: boolean) =>
  axios.put(PUT_AUTO_ASSIGN_BIENS_ENDPOINT, { status });

export const REQUEST_ADD_AI_RULE = (rule: string) =>
  axios.post(AI_RULE_ENDPOINT, { rule });

export const REQUEST_PUT_AI_RULE = (ruleId: string, rule: string) =>
  axios.put(AI_RULE_ENDPOINT, { ruleId, rule });

export const REQUEST_DELETE_AI_RULE = (ruleId: string) =>
  axios.delete(AI_RULE_ENDPOINT, { data: { ruleId } });

export const REQUEST_ADD_FAST_REPLY = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => axios.post(FAST_REPLY_ENDPOINT, { title, message });

export const REQUEST_PUT_FAST_REPLY = ({
  fastReplyId,
  title,
  message,
}: {
  fastReplyId: string;
  title: string;
  message: string;
}) => axios.put(FAST_REPLY_ENDPOINT, { fastReplyId, title, message });

export const REQUEST_DELETE_FAST_REPLY = (fastReplyId: string) =>
  axios.delete(FAST_REPLY_ENDPOINT, { data: { fastReplyId } });

export const REQUEST_CUSTOMER_ASSIGN_TO_AGENT = ({
  customerId,
  agentId,
}: {
  customerId: string;
  agentId: string;
}) => axios.post(CUSTOMER_ASSIGN_TO_AGENT_ENDPOINT, { customerId, agentId });

export const REQUEST_SEND_MESSAGE = ({
  customerId,
  messageText,
  products,
}: {
  customerId: string;
  messageText: string;
  products?: Product[];
}) => axios.post(SEND_MESSAGE_ENDPOINT, { customerId, messageText, products });

export const REQUEST_PUT_MESSAGE_STATUS = (customerId: string) =>
  axios.put(PUT_MESSAGE_STATUS_ENDPOINT, { customerId });

export const REQUEST_GET_CONVERSATION_BY_ID = (conversationId: string) =>
  axios.post(CONVERSATION_ENDPOINT, { conversationId });

export const REQUEST_SEARCH_PRODUCTS = ({
  searchText,
}: {
  searchText: string;
}) => axios.get(`${SEARCH_PRODUCTS_ENDPOINT}?searchText=${searchText}`);
