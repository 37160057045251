import React, { memo } from "react";
import { Message } from "../../store/slices/conversationsSlice";
import moment from "moment";
import Button from "../Button";
import { useTranslation } from "react-i18next";

type Props = {
  message: Message;
};

const userMessage = "bg-white rounded-br-lg items-start";
const assistantMessage = "bg-sky-200 rounded-bl-lg items-end";

const ChatBubble: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-full flex ${message.role === "user" ? "justify-start" : "justify-end"}`}
    >
      <div
        className={`w-fit mx-5 my-2 pt-2 pb-1 px-3 rounded-t-lg shadow text-sm flex flex-col whitespace-pre-line max-w-[60vw] lg:max-w-[30vw] ${message.role === "user" ? userMessage : assistantMessage} ${message.status === "sending" ? " opacity-50" : message.status === "error" ? "bg-red-300" : ""}`}
      >
        {message.content}
        {message.products?.length ? (
          <div className="flex overflow-x-auto mt-2 w-full">
            {message.products.map((item, i, productsArr) => (
              <div
                key={item.id}
                className={`max-w-40 min-w-40 flex flex-col items-center bg-gray-100 p-2 rounded-lg ${i !== productsArr.length - 1 ? "me-2" : ""}`}
              >
                <img
                  src={item.photoUrl}
                  alt={item.name}
                  className="h-44 min-w-32 max-w-32 rounded-lg"
                />
                <div className="h-12">
                  <h6 className="line-clamp-2 my-1">{item.name}</h6>
                </div>
                <Button
                  title={t("go_to_product")}
                  type="button"
                  size="sm"
                  className="w-full justify-center"
                  onClick={() => window.open(item.url, "_blank")}
                />
              </div>
            ))}
          </div>
        ) : null}
        <span className="text-[10px] text-gray-500">
          {moment(message.createdAt).format("HH:mm")}
        </span>
      </div>
    </div>
  );
};

export default memo(ChatBubble);
